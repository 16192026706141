import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";

//models
import UserClass from "../General/LocalStorage/UserClass"

const ProtectedRoute = (props) => {

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const userToken = UserClass.getInstance().getTokenUser();
        if (!userToken || userToken === 'undefined' || userToken === null) {
            setIsLoggedIn(false);
            return navigate(`${process.env.PUBLIC_URL}/`);
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}

export default ProtectedRoute;
