//General
import {getTokenUser, getRefreshTokenUser, getUser, getUserClientId, removeTokenUser, removeRefreshTokenUser, removeUser, removeUserClientId} from "./storage";

export default class UserClass {

    static instance = null;
    static createInstance() {
        let object = new UserClass();
        return object;
    }

    static getInstance () {
        if (!UserClass.instance) {
            UserClass.instance = UserClass.createInstance();
        }
        return UserClass.instance;
    }

    getUser() {
        return JSON.parse(getUser());
    }

    getTokenUser() {
        return getTokenUser();
    }

    getRefreshTokenUser() {
        return getRefreshTokenUser();
    }

    getClientIdUser() {
        return getUserClientId();
    }

    resetUser() {
        removeTokenUser();
        removeRefreshTokenUser();
        removeUser();
        removeUserClientId();
        UserClass.instance = null;
    }
}
